import {
	Divider,
	Stack,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { DayString, dayStrings } from '../../../../../constants/Common';
import { TimesheetSiteLog } from '../../../../../constants/Timesheet/Timesheet';
import { CreateColumnTitle } from './CreateColumnTitle';
import { CreateRow } from './CreateRow';
import { SiteLogCell } from './SiteLogCell';
import { TimesheetSectionNames } from './TimesheetSectionNames';

export type CreateWeekListProps = {
	siteLogs: Record<
		DayString,
		{
			In: TimesheetSiteLog | null;
			Out: TimesheetSiteLog | null;
			formattedLogs: string | null;
		}
	>;
};

export const CreateWeekList = ({
	siteLogs,
}: CreateWeekListProps): JSX.Element => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Stack
			data-testid="create-week-list"
			overflow="auto"
			gap={1}
			divider={
				<Divider
					color={theme.palette.neutral.light}
					sx={{ opacity: 0.5 }}
				/>
			}>
			{!isSmallScreen && (
				<CreateRow
					key="create-timesheet-column-title-row"
					customContainerProps={{
						'data-testid': 'create-timesheet-column-title-row',
					}}
					day={
						<CreateColumnTitle title={TimesheetSectionNames.DAY} />
					}
					sitelog={
						<CreateColumnTitle
							centerText
							title={TimesheetSectionNames.SITE_LOG_HOURS}
						/>
					}
					timesheetHours={
						<CreateColumnTitle
							centerText
							title={TimesheetSectionNames.TIMESHEET_HOURS}
						/>
					}
					breaks={
						<CreateColumnTitle
							centerText
							title={TimesheetSectionNames.UNPAID_BREAKS}
						/>
					}
					notes={
						<CreateColumnTitle
							centerText
							title={TimesheetSectionNames.NOTES}
						/>
					}
				/>
			)}
			{dayStrings.map((day) => (
				<CreateRow
					key={day}
					customContainerProps={{
						'data-testid': `create-timesheet-row-${day}`,
					}}
					day={<Typography fontWeight="inherit">{day}</Typography>}
					sitelog={<SiteLogCell siteLog={siteLogs[day]} />}
					timesheetHours={
						<Typography justifySelf="center">-</Typography>
					}
					breaks={<Typography justifySelf="center">-</Typography>}
					notes={<Typography justifySelf="center">-</Typography>}
				/>
			))}
		</Stack>
	);
};
