import { PropsOf } from '@emotion/react';
import { Box, Button, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { endOfWeek, startOfWeek } from 'date-fns';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { ReactNode, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CloudFunctionApi } from '../../../../cloudfunctions';
import {
	accountTypes,
	Company,
	UserDetails,
} from '../../../../constants/Common';
import {
	InvoiceStatuses,
	isTempTimesheet,
	ProjectTrackingStatuses,
	TempTimesheet,
	Timesheet,
	TimesheetPayrollStatus,
} from '../../../../constants/Timesheet/Timesheet';
import {
	MultiStageStatus,
	TimesheetStatus,
} from '../../../../constants/Timesheet/TimesheetStatus';
import { statusIcon } from '../../../../constants/Timesheet/TimesheetUtilities';
import { FirebaseApi } from '../../../../firebase/firebaseApi';
import { DateDataTableWithID } from '../../../DataTable/DateDataTableWithID';
import {
	formatFullDateTime,
	formatSearchParamsDate,
	formatSlashedDate,
} from '../../../helpers/dateFormatters';
import { getWeekString } from '../../../helpers/dateUtilities';
import {
	timesheetHoursCustomTableSort,
	timesheetPreApprovalHoursCustomTableSort,
} from '../../../helpers/muiDataTableCustomSorts';
import { LoadingDots } from '../../../Management/subcomponents/LoadingDots';
import { CustomSnackBar } from '../../../SnackBar/SnackBar';
import {
	IntegrationStatusChip,
	StatusChipType,
} from '../../IntegrationStatusChip';
import { getTimesheetActions } from '../../timesheetActions';
import { FilterChip } from './FilterChip';
import { NoTimesheetDialog } from './NoTimesheetDialog';
import { OverviewTableTheme } from './OverviewTableTheme';
import { TimesheetHoursTooltip } from './TimesheetHoursTooltip/TimesheetHoursTooltip';

type CurrentTimesheetStatuses =
	| TimesheetStatus.Active
	| TimesheetStatus.Approved
	| TimesheetStatus.Submitted
	| 'all'
	| 'notCreated';

export type TimesheetFilter = 'All' | 'Approved' | 'Submitted' | 'Unsubmitted';

const timesheetDisplayFilters: Record<
	TimesheetFilter,
	CurrentTimesheetStatuses[]
> = {
	All: ['all'],
	Approved: [TimesheetStatus.Approved],
	Submitted: [TimesheetStatus.Submitted],
	Unsubmitted: [TimesheetStatus.Active, 'notCreated'],
};

enum OptionText {
	Info = 'Info',
	Review = 'Review',
	Open = 'Open',
}

export type OverviewFirebaseCalls =
	| 'getActivitiesByTimesheet'
	| 'updateTimesheetActivities'
	| 'preApproveTimesheet'
	| 'updateUserDetailsSiteInfo'
	| 'updateUserDetailsCompanyInfo'
	| 'deleteTimesheet';

type FormattedTimesheet = {
	id: string;
	name: string;
	siteName: Timesheet['site']['name'];
	date: string | null;
	payrollStatus: Timesheet['payrollStatus'];
	projectTrackingStatus: Timesheet['projectTrackingStatus'];
	invoiceStatus: Timesheet['invoiceStatus'];
	totalHours: Timesheet['hours'];
	actions: {
		options: {
			optionText: string;
			timesheetID: string;
		};
		tableEntryID: string;
		canApprove: boolean;
	};
	timesheetStatus: TimesheetStatus | null;
	employerName: Timesheet['employer']['name'];
	contractedToName: NonNullable<Timesheet['contractedTo']>['name'];
	preApproval: Timesheet['preApproval'];
};

const optionButtonFormatting: Record<
	'option' | 'approve',
	Record<`${boolean}`, Pick<PropsOf<typeof Grid>, 'xs' | 'sm' | 'md' | 'lg'>>
> = {
	option: {
		true: { xs: 8, sm: 4, md: 8, lg: 4 },
		false: { xs: 12, sm: 6, md: 12, lg: 6 },
	},
	approve: {
		true: { xs: 16, sm: 8, md: 16, lg: 8 },
		false: { xs: 12, sm: 6, md: 12, lg: 6 },
	},
};

export type OverviewProps = {
	userDetails: UserDetails;
	userCompany: Company;
	timesheetData: (TempTimesheet | Timesheet)[];
	users: Record<string, UserDetails>;
	weekEnding: Date;
	setWeekEnding: (date: Date) => void;
	selected: string | null;
	setSelected: (selected: string) => void;
	loading: boolean;
	firebaseApi: Pick<FirebaseApi, OverviewFirebaseCalls>;
	cloudFunctionApi: Pick<
		CloudFunctionApi,
		'sendTimesheetReminderNotification'
	>;
};

export const Overview = ({
	userDetails,
	userCompany,
	timesheetData,
	users,
	weekEnding,
	setWeekEnding,
	selected,
	setSelected,
	loading,
	firebaseApi,
	cloudFunctionApi,
}: OverviewProps): JSX.Element => {
	const canApproveTimesheets = (
		accountType: string,
		companyID: string,
		contractedToID: string | null,
	): boolean => {
		return (
			accountType === accountTypes.management ||
			accountType === accountTypes.seniorManagement ||
			(accountType === accountTypes.handler &&
				contractedToID === companyID)
		);
	};
	const usesTimesheetReviewTabName =
		userDetails.accountType === accountTypes.management ||
		userDetails.accountType === accountTypes.seniorManagement;
	const viewEmployerName =
		userDetails.accountType === accountTypes.management ||
		userDetails.accountType === accountTypes.seniorManagement;
	const viewClientName = userDetails.accountType === accountTypes.handler;
	const canRemoveFromCompany =
		userDetails.accountType === accountTypes.handler;
	const canRemoveFromSite =
		userDetails.accountType === accountTypes.management;

	const preApprovalsEnabled = !!userCompany.multiStageApproval;

	const [tableData, setTableData] = useState<
		Record<string, FormattedTimesheet>
	>({});
	const [timesheetFilter, setTimesheetFilter] =
		useState<TimesheetFilter>('All');
	const [timesheetStatusCount, setTimesheetStatusCount] = useState<
		Record<TimesheetFilter, number>
	>({
		All: 0,
		Approved: 0,
		Submitted: 0,
		Unsubmitted: 0,
	});
	const [noTimesheetDialogOpen, setNoTimesheetDialogOpen] = useState(false);
	const [notificationSnackBarOpen, setNotificationSnackBarOpen] =
		useState(false);
	const [notificationSuccess, setNotificationSuccess] = useState<
		boolean | undefined
	>();
	const [removeSnackBarOpen, setRemoveSnackBarOpen] = useState(false);
	const [removeSuccess, setRemoveSuccess] = useState(false);

	const tableList = Object.values(tableData);
	const {
		payrollIntegrated,
		projectTrackingIntegrated,
		invoicingIntegrated,
	} = userCompany;

	useEffect(() => {
		if (!timesheetData) return;

		const optionText = (
			status: TimesheetStatus | null,
			canApprove: boolean,
		): OptionText =>
			status === TimesheetStatus.Active || status === null
				? OptionText.Info
				: status === TimesheetStatus.Submitted && canApprove
				? OptionText.Review
				: OptionText.Open;

		const mapTableData = (
			timesheet: TempTimesheet | Timesheet,
		): FormattedTimesheet => {
			const dateText = timesheet.dateSubmitted
				? formatSlashedDate(timesheet.dateSubmitted.toDate())
				: null;
			const canApprove = canApproveTimesheets(
				userDetails.accountType,
				userDetails.companyID,
				timesheet.contractedTo?.id ?? null,
			);

			return {
				// shared columns
				id: timesheet.id,
				name: timesheet.employee.name,
				siteName: timesheet.site.name,
				invoiceStatus: timesheet.invoiceStatus,
				date: dateText,
				payrollStatus: timesheet.payrollStatus,
				projectTrackingStatus: timesheet.projectTrackingStatus,
				actions: {
					options: {
						optionText: optionText(
							timesheet.timesheetStatus,
							canApprove,
						),
						timesheetID: timesheet.id,
					},
					tableEntryID: timesheet.employee.id + timesheet.id,
					canApprove: canApprove,
				},
				timesheetStatus: timesheet.timesheetStatus,
				totalHours: timesheet.hours,
				// Employee only table column data
				employerName: timesheet.employer.name,

				// Client only table data
				contractedToName: timesheet.contractedTo?.name || 'No Client',
				preApproval: timesheet?.preApproval,
			};
		};

		if (timesheetFilter === 'Unsubmitted') {
			const filteredTimesheets = timesheetData.filter(
				(timesheet) =>
					!timesheet.timesheetStatus ||
					timesheet.timesheetStatus === TimesheetStatus.Active,
			);
			const sheetsObject = Object.fromEntries(
				filteredTimesheets.map((timesheet) => [
					timesheet.employee.id + timesheet.id,
					mapTableData(timesheet),
				]),
			);
			setTableData(sheetsObject);
		} else if (timesheetFilter === 'Submitted') {
			const filteredTimesheets = timesheetData.filter(
				(timesheet) =>
					timesheet.timesheetStatus === TimesheetStatus.Submitted,
			);
			const sheetsObject = Object.fromEntries(
				filteredTimesheets.map((timesheet) => [
					timesheet.employee.id + timesheet.id,
					mapTableData(timesheet),
				]),
			);
			setTableData(sheetsObject);
		} else if (timesheetFilter !== 'All') {
			const filteredTimesheets = timesheetData.filter(
				(timesheet) => timesheet.timesheetStatus === timesheetFilter,
			);
			const sheetsObject = Object.fromEntries(
				filteredTimesheets.map((timesheet) => [
					timesheet.employee.id + timesheet.id,
					mapTableData(timesheet),
				]),
			);
			setTableData(sheetsObject);
		} else {
			const sheetsObject = Object.fromEntries(
				timesheetData.map((timesheet) => [
					timesheet.employee.id + timesheet.id,
					mapTableData(timesheet),
				]),
			);
			setTableData(sheetsObject);
		}
	}, [
		timesheetData,
		timesheetFilter,
		userDetails.accountType,
		userDetails.companyID,
		weekEnding,
	]);

	useEffect(() => {
		if (timesheetData.length === 0) {
			setTimesheetStatusCount({
				All: 0,
				Approved: 0,
				Submitted: 0,
				Unsubmitted: 0,
			});
		} else {
			const approved = timesheetData.filter(
				(timesheet) =>
					timesheet.timesheetStatus === TimesheetStatus.Approved,
			).length;
			const submitted = timesheetData.filter(
				(timesheet) =>
					timesheet.timesheetStatus === TimesheetStatus.Submitted,
			).length;
			const unsubmitted = timesheetData.filter(
				(timesheet) =>
					!timesheet.timesheetStatus ||
					timesheet.timesheetStatus === TimesheetStatus.Active,
			).length;
			setTimesheetStatusCount({
				All: approved + submitted + unsubmitted,
				Approved: approved,
				Submitted: submitted,
				Unsubmitted: unsubmitted,
			});
		}
	}, [timesheetData]);

	const renderEntryOptionBtn = ({
		optionText,
		timesheetID,
	}: {
		optionText: string;
		timesheetID: string;
	}): JSX.Element => {
		const params = new URLSearchParams({
			timesheetID,
			endDate: formatSearchParamsDate(weekEnding),
		});
		return optionText === OptionText.Info ? (
			<Button
				fullWidth
				variant="outlined"
				onClick={(): void => {
					setNoTimesheetDialogOpen(true);
				}}>
				{optionText}
			</Button>
		) : (
			<Link
				to={
					usesTimesheetReviewTabName
						? `../review?${params}`
						: `../details?${params}`
				}
				style={{ textDecoration: 'none' }}>
				<Button
					fullWidth
					variant="outlined"
					onClick={(): void => setSelected(timesheetID)}>
					{optionText}
				</Button>
			</Link>
		);
	};

	const tableLoadingOpt: Partial<MUIDataTableOptions> = {
		tableBodyHeight: 'calc(100vh - 330px)',
		filter: false,
		print: false,
		downloadOptions: {
			filename: `Timesheet Overview - WE ${weekEnding.toLocaleDateString()}`,
		},
		textLabels: {
			body: {
				noMatch: loading ? (
					<LoadingDots />
				) : (
					'Sorry, no matching timesheets found'
				),
			},
		},
		setTableProps: () => ({
			style: { tableLayout: 'fixed' },
		}),
	};

	const renderEntryApproveBtn = (
		tableEntryID: string,
	): JSX.Element | undefined => {
		const timesheet = tableData[tableEntryID];
		if (
			!timesheet ||
			(timesheet.timesheetStatus &&
				timesheet.timesheetStatus === TimesheetStatus.Archived)
		)
			return <></>;
		if (
			!timesheet.timesheetStatus ||
			timesheet.timesheetStatus === TimesheetStatus.Active ||
			timesheet.timesheetStatus === TimesheetStatus.Approved
		)
			return (
				<Button
					variant="contained"
					fullWidth
					sx={{ whiteSpace: 'nowrap' }}
					disabled>
					{userDetails.disableTimesheetApproval
						? 'pre approve'
						: 'approve'}
				</Button>
			);

		const unformattedTimesheet = timesheetData.find(
			(item) => item.id === timesheet.id,
		);

		if (
			unformattedTimesheet === undefined ||
			!unformattedTimesheet.timesheetStatus
		)
			return;
		const getAction = getTimesheetActions(userDetails, firebaseApi);
		const action = getAction(
			timesheet.timesheetStatus,
			!!timesheet.preApproval,
		);
		const activities = firebaseApi.getActivitiesByTimesheet(timesheet.id);

		return (
			<Button
				data-testid={tableEntryID + '-option-button'}
				variant="contained"
				fullWidth
				sx={{ whiteSpace: 'nowrap' }}
				onClick={async (): Promise<void> =>
					await action.onClick(unformattedTimesheet, await activities)
				}
				disabled={
					action.disabled ||
					timesheet.timesheetStatus !== TimesheetStatus.Submitted ||
					!tableEntryID
				}>
				{action.buttonText}
			</Button>
		);
	};

	const numCells =
		4 +
		(viewEmployerName ? 1 : 0) +
		(viewClientName ? 1 : 0) +
		(payrollIntegrated ? 1 : 0) +
		(invoicingIntegrated ? 1 : 0) +
		(projectTrackingIntegrated ? 1 : 0) +
		(preApprovalsEnabled ? 1 : 0);

	const centeredColumns = [...Array(numCells).keys()].slice(4);

	const cellWidth = { width: `${100 / numCells}%` };
	const cellWidthSmall = `${(100 / numCells) * 0.75}%`;
	const cellWidthLarge = `${(100 / numCells) * 1.25}%`;

	const setCellHeaderProps = (): {
		style: {
			width: string;
		};
	} => ({
		style: { ...cellWidth },
	});

	const columns: MUIDataTableColumnDef[] = [
		{
			label: 'Name',
			name: 'name',
			options: {
				setCellHeaderProps: () => ({
					style: {
						...setCellHeaderProps().style,
						width: cellWidthLarge,
					},
				}),
				customBodyRenderLite: (dataIndex: number): React.ReactNode => {
					const date =
						tableList[dataIndex]?.timesheetStatus ===
						TimesheetStatus.Submitted
							? tableList[dataIndex]?.date
							: null;
					const status =
						tableList[dataIndex]?.preApproval &&
						tableList[dataIndex].timesheetStatus ===
							TimesheetStatus.Submitted
							? MultiStageStatus.PreApproved
							: tableList[dataIndex].timesheetStatus;
					return (
						<Stack direction="row" spacing={1} alignItems="center">
							<Box display="flex" justifyContent="center">
								{statusIcon(status, date)}
							</Box>
							<Tooltip title={tableList[dataIndex].name}>
								<Box
									alignSelf="center"
									textOverflow="ellipsis"
									overflow="hidden">
									{tableList[dataIndex].name}
								</Box>
							</Tooltip>
						</Stack>
					);
				},
			},
		},
		...(viewEmployerName
			? [
					{
						label: 'Employer',
						name: 'employerName',
						options: {
							setCellHeaderProps,
						},
					},
			  ]
			: []),

		...(viewClientName
			? [
					{
						label: 'Client',
						name: 'contractedToName',
						options: {
							setCellHeaderProps,
						},
					},
			  ]
			: []),
		{
			label: 'Site',
			name: 'siteName',
			options: {
				setCellHeaderProps,
			},
		},
		...(preApprovalsEnabled
			? [
					{
						label: 'Pre Approved',
						name: 'preApproval',
						options: {
							sortCompare:
								timesheetPreApprovalHoursCustomTableSort,
							setCellHeaderProps: () => ({
								style: {
									...setCellHeaderProps().style,
									whiteSpace: 'nowrap',
									textAlign: 'center',
									width: cellWidthSmall,
								},
							}),
							customBodyRenderLite: (
								dataIndex: number,
							): ReactNode => {
								const preApproval =
									tableList[dataIndex].preApproval;

								return (
									<Box display="flex" justifyContent="center">
										{preApproval ? (
											<TimesheetHoursTooltip
												hours={preApproval.hours}
												title="Pre Approval"
												subtitle={
													<Grid container>
														<Grid item xs={12}>
															{
																preApproval
																	.reviewer
																	.name
															}
														</Grid>
														<Grid item xs={12}>
															{formatFullDateTime(
																preApproval.reviewedAt.toDate(),
															)}
														</Grid>
													</Grid>
												}>
												<Typography
													variant="inherit"
													sx={{
														textDecoration:
															'underline dotted',
													}}>
													{
														preApproval.hours.total
															.billable
													}
												</Typography>
											</TimesheetHoursTooltip>
										) : (
											'-'
										)}
									</Box>
								);
							},
						},
					},
			  ]
			: []),
		{
			label: 'Total',
			name: 'totalHours',
			options: {
				sortCompare: timesheetHoursCustomTableSort,
				setCellHeaderProps: () => ({
					style: {
						...setCellHeaderProps().style,
						textAlign: 'center',
						width: cellWidthSmall,
					},
				}),
				customBodyRenderLite: (dataIndex: number): ReactNode => {
					const totalHours = tableList[dataIndex].totalHours;
					return (
						<Box display="flex" justifyContent="center">
							<TimesheetHoursTooltip
								hours={totalHours}
								title="Total Hours">
								<Typography
									variant="inherit"
									sx={{
										textDecoration: 'underline dotted',
									}}>
									{totalHours.total.billable}
								</Typography>
							</TimesheetHoursTooltip>
						</Box>
					);
				},
			},
		},
		...(payrollIntegrated
			? [
					{
						label: 'Payroll Status',
						name: 'payrollStatus',
						options: {
							setCellHeaderProps: () => ({
								style: {
									...setCellHeaderProps().style,
									textAlign: 'center',
								},
							}),
							customBodyRender: (
								value: TimesheetPayrollStatus,
							) => (
								<Box display="flex" justifyContent="center">
									<IntegrationStatusChip
										type={StatusChipType.Payroll}
										status={value}
									/>
								</Box>
							),
						},
					},
			  ]
			: []),

		...(projectTrackingIntegrated
			? [
					{
						label: 'Project Tracking Status',
						name: 'projectTrackingStatus',
						options: {
							setCellHeaderProps: () => ({
								style: {
									...setCellHeaderProps().style,
									textAlign: 'center',
								},
							}),
							customBodyRender: (
								value: ProjectTrackingStatuses,
							) => (
								<Box display="flex" justifyContent="center">
									<IntegrationStatusChip
										type={StatusChipType.ProjectTracking}
										status={value}
									/>
								</Box>
							),
						},
					},
			  ]
			: []),
		...(invoicingIntegrated
			? [
					{
						label: 'Invoice Status',
						name: 'invoiceStatus',
						options: {
							setCellHeaderProps: () => ({
								style: {
									...setCellHeaderProps().style,
									textAlign: 'center',
								},
							}),
							customBodyRender: (value: InvoiceStatuses) => (
								<Box display="flex" justifyContent="center">
									<IntegrationStatusChip
										type={StatusChipType.Invoicing}
										status={value}
									/>
								</Box>
							),
						},
					},
			  ]
			: []),
		{
			label: 'Options',
			name: 'actions',
			options: {
				filter: false,
				download: false,
				sort: false,
				setCellHeaderProps: () => ({
					style: {
						...setCellHeaderProps().style,
						textAlign: 'center',
						width: cellWidthLarge,
					},
				}),
				customBodyRender: (value, _): JSX.Element => {
					return (
						<Box width="100%" px={1}>
							<Grid container spacing={1} justifyContent="center">
								<Grid
									item
									{...optionButtonFormatting['option'][
										`${!!userDetails.disableTimesheetApproval}`
									]}>
									{renderEntryOptionBtn(value.options)}
								</Grid>
								{value.canApprove && (
									<Grid
										item
										{...optionButtonFormatting['approve'][
											`${!!userDetails.disableTimesheetApproval}`
										]}>
										{renderEntryApproveBtn(
											value.tableEntryID,
										)}
									</Grid>
								)}
							</Grid>
						</Box>
					);
				},
			},
		},
	];

	const tableTitle = (
		<Grid container spacing={1} pt={1}>
			<Grid xs={12} item>
				<Typography variant="h4">
					{getWeekString(startOfWeek(weekEnding))}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Stack spacing={1} direction="row">
					{Object.keys(timesheetDisplayFilters).map((titleKey) => {
						const title = titleKey as TimesheetFilter;
						return (
							<FilterChip
								title={title}
								key={title}
								currentFilter={timesheetFilter}
								onClick={(): void => setTimesheetFilter(title)}
								count={timesheetStatusCount[title]}
							/>
						);
					})}
				</Stack>
			</Grid>
		</Grid>
	);

	const selectedTimesheet = timesheetData.find(
		(timesheet) => timesheet.id === selected,
	);

	return (
		<>
			{selectedTimesheet && noTimesheetDialogOpen && (
				<NoTimesheetDialog
					employee={users[selectedTimesheet.employee.id]}
					modalOpen={noTimesheetDialogOpen}
					weekEnding={weekEnding}
					endDate={endOfWeek(weekEnding)}
					timesheetID={
						!isTempTimesheet(selectedTimesheet)
							? selectedTimesheet.id
							: null
					}
					timesheetEmployeeName={selectedTimesheet.employee.name}
					timesheetStatus={selectedTimesheet.timesheetStatus}
					setModalOpen={setNoTimesheetDialogOpen}
					firebaseApi={firebaseApi}
					setNotificationSuccess={(
						success: boolean | undefined,
					): void => {
						setNotificationSuccess(success);
						setNotificationSnackBarOpen(true);
					}}
					canRemoveFromCompany={canRemoveFromCompany}
					canRemoveFromSite={canRemoveFromSite}
					setRemoveSuccess={(success: boolean): void => {
						setRemoveSuccess(success);
						setRemoveSnackBarOpen(true);
					}}
					cloudFunctionApi={cloudFunctionApi}
				/>
			)}
			<OverviewTableTheme centeredColumns={centeredColumns}>
				<DateDataTableWithID
					title={tableTitle}
					tableData={tableList}
					columns={columns}
					date={[weekEnding, setWeekEnding]}
					selection={[selected, setSelected]}
					customTableOptions={tableLoadingOpt}
					type="current"
					allowFuture
					weekEnding
					showWeekRange={false}
				/>
			</OverviewTableTheme>
			<CustomSnackBar
				open={notificationSnackBarOpen}
				onClose={(): void => setNotificationSnackBarOpen(false)}
				snackBarText={
					notificationSuccess
						? 'Success'
						: 'Could not send notification'
				}
				severity={notificationSuccess ? 'success' : 'error'}
			/>
			<CustomSnackBar
				open={removeSnackBarOpen}
				onClose={(): void => setRemoveSnackBarOpen(false)}
				snackBarText={
					removeSuccess ? 'Success' : 'Could not remove employee'
				}
				severity={removeSuccess ? 'success' : 'error'}
			/>
		</>
	);
};
