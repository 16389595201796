import {
	Grid,
	GridProps,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { TimesheetSectionNames } from './TimesheetSectionNames';

export type CreateRowProps = {
	day: JSX.Element;
	sitelog: JSX.Element;
	timesheetHours: JSX.Element;
	breaks: JSX.Element;
	notes: JSX.Element;
	customContainerProps?: GridProps & { 'data-testid'?: string };
};

/**
 * Manages the layout of rows in the create timesheet form.
 * Takes in elements to render for each column
 */
export const CreateRow = ({
	day,
	sitelog,
	timesheetHours,
	breaks,
	notes,
	customContainerProps,
}: CreateRowProps): JSX.Element => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Grid container px={2} columnSpacing={1} {...customContainerProps}>
			<Grid
				item
				xs={12}
				sm={12}
				md={2}
				lg={1}
				sx={{ fontWeight: isSmallScreen ? 'bold' : undefined }}>
				{day}
			</Grid>
			{isSmallScreen && (
				<Grid item xs={4} sm={4}>
					<Typography>
						{TimesheetSectionNames.SITE_LOG_HOURS}
					</Typography>
				</Grid>
			)}
			<Grid item xs={8} sm={8} md={2}>
				{sitelog}
			</Grid>
			<Grid item xs={12} sm={12} md={4}>
				{timesheetHours}
			</Grid>
			{isSmallScreen && (
				<Grid item xs={4} sm={4}>
					<Typography>
						{TimesheetSectionNames.UNPAID_BREAKS}
					</Typography>
				</Grid>
			)}
			<Grid item xs={8} sm={8} md={2}>
				{breaks}
			</Grid>
			{isSmallScreen && (
				<Grid item xs={12} sm={12}>
					<Typography>{TimesheetSectionNames.NOTES}</Typography>
				</Grid>
			)}
			<Grid item xs={12} sm={12} md={2} lg={3}>
				{notes}
			</Grid>
		</Grid>
	);
};
