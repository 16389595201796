import { Action } from '../../../../constants/TypescriptUtilities';
import { CreateState } from './reducer';

type CreateAction<
	T extends CreateActionTypes,
	P extends object | void = void,
> = Action<T, P>;

export const enum CreateActionTypes {
	SELECT_WORKER = 'SELECT_WORKER',
	SELECT_CLIENT = 'SELECT_CLIENT',
	SELECT_SITE = 'SELECT_SITE',
	SELECT_TIMESHEET_STATUS = 'SELECT_TIMESHEET_STATUS',
	SELECT_WEEK_ENDING = 'SELECT_WEEK_ENDING',
	UPDATE_LOADING = 'UPDATE_LOADING',
	UPDATE_SITE_LOGS = 'UPDATE_SITE_LOGS',
}

type SelectWorkerAction = CreateAction<
	CreateActionTypes.SELECT_WORKER,
	{ worker: CreateState['selectedWorker'] }
>;

type SelectClientAction = Action<
	CreateActionTypes.SELECT_CLIENT,
	{ client: CreateState['selectedClient'] }
>;

type SelectSiteAction = Action<
	CreateActionTypes.SELECT_SITE,
	{ site: CreateState['selectedSite'] }
>;

type SelectTimesheetStatusAction = Action<
	CreateActionTypes.SELECT_TIMESHEET_STATUS,
	{ timesheetStatus: CreateState['selectedTimesheetStatus'] }
>;

type SelectWeekEndingAction = Action<
	CreateActionTypes.SELECT_WEEK_ENDING,
	{ weekEnding: CreateState['selectedWeekEnding'] }
>;

type UpdateLoadingAction = Action<
	CreateActionTypes.UPDATE_LOADING,
	{ loading: CreateState['initialLoad'] }
>;

type UpdateSiteLogsAction = Action<
	CreateActionTypes.UPDATE_SITE_LOGS,
	{ siteLogs: CreateState['siteLogs'] }
>;

export type CreateActions =
	| SelectWorkerAction
	| SelectClientAction
	| SelectSiteAction
	| SelectTimesheetStatusAction
	| SelectWeekEndingAction
	| UpdateLoadingAction
	| UpdateSiteLogsAction;

export const selectWorker = (
	worker: CreateState['selectedWorker'],
): SelectWorkerAction => ({
	type: CreateActionTypes.SELECT_WORKER,
	payload: { worker },
});

export const selectClient = (
	client: CreateState['selectedClient'],
): SelectClientAction => ({
	type: CreateActionTypes.SELECT_CLIENT,
	payload: { client },
});

export const selectSite = (
	site: CreateState['selectedSite'],
): SelectSiteAction => ({
	type: CreateActionTypes.SELECT_SITE,
	payload: { site },
});

export const selectTimesheetStatus = (
	timesheetStatus: CreateState['selectedTimesheetStatus'],
): SelectTimesheetStatusAction => ({
	type: CreateActionTypes.SELECT_TIMESHEET_STATUS,
	payload: { timesheetStatus },
});

export const selectWeekEnding = (
	weekEnding: CreateState['selectedWeekEnding'],
): SelectWeekEndingAction => ({
	type: CreateActionTypes.SELECT_WEEK_ENDING,
	payload: { weekEnding },
});

export const updateLoading = (
	loading: CreateState['initialLoad'],
): UpdateLoadingAction => ({
	type: CreateActionTypes.UPDATE_LOADING,
	payload: { loading },
});

export const updateSiteLogs = (
	siteLogs: CreateState['siteLogs'],
): UpdateSiteLogsAction => ({
	type: CreateActionTypes.UPDATE_SITE_LOGS,
	payload: { siteLogs },
});
