import { Typography } from '@mui/material';
import { getTimeForHours } from '../../../../../constants/stringUtilities';
import { TimesheetSiteLog } from '../../../../../constants/Timesheet/Timesheet';
import { SiteLogPicturesTooltip } from '../../SiteLogPicturesTooltip';

export type SiteLogCellProps = {
	siteLog: {
		In: TimesheetSiteLog | null;
		Out: TimesheetSiteLog | null;
		formattedLogs: string | null;
	};
};

export const SiteLogCell = ({ siteLog }: SiteLogCellProps): JSX.Element => {
	return siteLog.formattedLogs ? (
		<SiteLogPicturesTooltip
			signInURL={siteLog.In?.url ?? ''}
			signOutURL={siteLog.Out?.url ?? ''}>
			<Typography
				fontSize="small"
				textAlign="center"
				sx={{
					textWrap: 'pretty',
					textDecoration: 'underline dotted',
				}}>
				{siteLog.formattedLogs}
			</Typography>
		</SiteLogPicturesTooltip>
	) : (
		<Typography
			fontSize="small"
			textAlign="center"
			sx={{
				textWrap: 'pretty',
			}}>
			{getTimeForHours(0)}
		</Typography>
	);
};
